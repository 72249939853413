import React from 'react';

const News = (props)=>{
    return(
        <div className='bodyContainer'>
        
        </div>
    )
}

export default News;